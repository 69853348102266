<template>
  <div class="container">

    <div class="row">
      <div class="col">
        <h1>God Jul 2021!</h1>
        <p>
          Dersom du har fått en link til denne siden i julegave er du nå den stolte eier av følgende krypto!
        </p>
        <p>
          Kontakt avsenderen av gaven dersom du har lyst til å overføre dem noe sted.
        </p>
      </div>
      <div class="col">
        <img style="width: 300px;"
             :src="require('@/assets/Nissen.gif')" />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <Assets/>
      </div>
    </div>

    <div class="row">
      <div class="col">
        Hilsen Kryptonissen og hjelperene!
      </div>
    </div>

  </div>
</template>

<script>
import Assets from "./Assets";

export default {
  name: "Frontpage",
  components: {Assets}
}
</script>

<style scoped>

</style>
