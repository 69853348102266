<template>
  <div>
    <table class="table table-striped">
      <thead>
      <tr>
        <th/>
        <th class="text-start"></th>
        <th class="text-start">Beskrivelse</th>
        <th class="text-end">Beholdning</th>
        <th class="text-end">Verdi 30.10.21</th>
        <th class="text-end">Verdi nå</th>
        <th class="text-end">Endring</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="asset in assets" :key="asset">
        <td>
          <img style="width: 22px"
               :alt="asset.name"
               :src="asset.img">
        </td>
        <td class="text-start">{{ asset.name }}</td>
        <td class="text-start">{{ asset.desc }}</td>
        <td class="text-end">{{ asset.amount.toFixed(4) }}</td>
        <td class="text-end">${{ asset.value3010.toFixed(2) }}</td>
        <td class="text-end">
          <span v-if="asset.valueNow">
            ${{ asset.valueNow.toFixed(2) }}
          </span>
        </td>
        <td class="text-end">
          <span v-if="asset.change">
            {{ asset.change.toFixed(2) }}%
          </span>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <th colspan="4"/>
      <th class="text-end">${{ sum3010.toFixed(2) }}</th>
      <th class="text-end">${{ sumNow.toFixed(2) }}</th>
      <th class="text-end"><span v-if="change">
        {{ change.toFixed(2) }}%
      </span>
      </th>
      </tfoot>

    </table>
  </div>
</template>

<script>
import axios from "axios";

const assets = [
  {
    name: "xJEWEL",
    desc: "Staked Jewel",
    amount: 9 / 12,
    value3010: 5.38,
    valueNow: 0.0,
    url: "https://defikingdoms.com/",
    img: require('@/assets/JEWEL.png')
  },
  {
    name: "1WBTC",
    desc: "Wrapped Bitcoin",
    amount: 0.0012 / 12,
    value3010: 6.11,
    valueNow: 0.0,
    img: require('@/assets/wbtc.svg'),

  },
  {
    name: "1ETH",
    desc: "Wrapped Ethereum",
    amount: 0.018 / 12,
    value3010: 6.4,
    valueNow: 0.0,
    img: require('@/assets/eth.svg'),
  },
  {
    name: "ONE",
    desc: "Harmony ONE",
    amount: 96 / 12,
    value3010: 2.64,
    valueNow: 0.0,
    img: require('@/assets/one.svg'),
  },
  {
    name: "xTRANQ",
    desc: "Staked Tranquil",
    amount: 72 / 12,
    value3010: 2.38,
    valueNow: 0.0,
    url: "https://www.tranquil.finance/",
    img: require('@/assets/xtranq.svg'),
  },
]

export default {
  name: "Assets",
  data() {
    return {

      prices: {},
      xJewelRatio: 0,
    }
  },
  computed: {
    assets() {
      const ret = []
      for (const asset of assets) {
        const a = {...asset}

        if (this.prices[asset.name])
          a.valueNow = a.amount * this.prices[a.name]

        if (a.name === "xJEWEL" && this.xJewelRatio && this.prices["JEWEL"])
          a.valueNow = a.amount * this.prices["JEWEL"] * this.xJewelRatio

        if (a.valueNow)
          a.change = (a.valueNow - a.value3010) / a.value3010 * 100

        ret.push(a)
      }

      console.info(ret)
      return ret
    },
    sum3010() {
      let sum = 0

      for (const a of assets)
        sum += a.value3010

      return sum
    },
    sumNow() {
      let sum = 0

      for (const a of this.assets)
        sum += a.valueNow

      return sum
    },
    change() {
      if (this.sumNow && this.sum3010) {
        return (this.sumNow - this.sum3010) / this.sum3010 * 100
      } else {
        return 0
      }
    }
  },
  methods: {
    fetchPrices() {
      const tokenIds = ["harmony", "bitcoin", "ethereum", "tranquil-finance", "defi-kingdoms"]

      if (tokenIds.length > 50)
        throw "Too many ids in getExternalPrices"

      const ids = tokenIds.join('%2C')
      const url = 'https://api.coingecko.com/api/v3/simple/price?ids=' + ids + '&vs_currencies=usd'

      return axios.get(url)
          .then(resp => {
            for (const [key, v] of Object.entries(resp.data)) {
              if (v.usd) this.prices[key.toLowerCase()] = v.usd
            }
            if (this.prices["harmony"]) {
              this.prices["WONE"] = this.prices["harmony"]
              this.prices["ONE"] = this.prices["harmony"]
            }
            if (this.prices["bitcoin"]) {
              this.prices["1WBTC"] = this.prices["bitcoin"]
            }
            if (this.prices["ethereum"]) {
              this.prices["1ETH"] = this.prices["ethereum"]
            }
            if (this.prices["tranquil-finance"]) {
              this.prices["xTRANQ"] = this.prices["tranquil-finance"]
            }
            if (this.prices["defi-kingdoms"]) {
              this.prices["JEWEL"] = this.prices["defi-kingdoms"]
            }
          })
    },
    fetchXJewelRatio() {
      axios.get("https://us-east1-dfkwatch-328521.cloudfunctions.net/xJewelRatioHistory")
          .then(response => {
            if (response.status === 200) {
              this.xJewelRatio = response.data[0].ratio
            }

            console.info(response)


          })
    }
  },
  mounted() {
    this.fetchPrices()
    this.fetchXJewelRatio()
  }
}
</script>

<style scoped>

</style>
